import React from 'react';
import SEO from '../components/seo';
import './stylesheets/general.scss';
import { Link } from 'gatsby';

import '../components/index.scss';
import Loader from '../components/loader';
import ContextConsumer from '../components/context';

const EN = require('../components/copy/EN.json');
const IT = require('../components/copy/IT.json');
let currentLanguage;
let language;
const img1 = require('../images/fishmeal.jpg');

class Livestock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  loader() {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  renderIndex() {
    return (
      <>
        <ContextConsumer>
          {({ data }) => {
            language = data.contextLanguage;

            language === 'IT' ? (currentLanguage = IT) : (currentLanguage = EN);
            return (
              <div className="general-container">
                <SEO title="Bocciardo - Ovine & Fish Meals" />
                <h2>{currentLanguage.Livestock.title}</h2>
                <div className="img-wool-container nuts">
                  <img src={img1} />
                </div>
                <p>{currentLanguage.Livestock.text}</p>
                <div className="loactions">
                  <p>
                    <strong>
                      {currentLanguage === IT
                        ? 'SUD AFRICA -'
                        : 'SOUTH AFRICA -'}
                    </strong>{' '}
                    {currentLanguage.Livestock.textTwo}
                  </p>
                  <p>
                    <strong>AUSTRALIA -</strong>{' '}
                    {currentLanguage.Livestock.textThree}
                  </p>
                  <p>
                    <strong>
                      {' '}
                      {currentLanguage === IT
                        ? 'NUOVA ZELANDA -'
                        : 'NEW ZELAND -'}
                    </strong>{' '}
                    {currentLanguage.Livestock.textThree}
                  </p>
                </div>
                <Link
                  to="/contact_us"
                  style={{
                    textDecorationColor: 'black',
                  }}
                >
                  <p className="contact">
                    {currentLanguage.Livestock.informazioni}
                  </p>
                </Link>
              </div>
            );
          }}
        </ContextConsumer>
      </>
    );
  }

  render() {
    return <>{this.renderIndex()}</>;
  }
}

export default Livestock;
